var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "pt-6 ml-4 mr-4", attrs: { "align-h": "center" } },
    [
      _c(
        "b-col",
        { attrs: { cols: "12", md: "9", lg: "8", xl: "8", xxl: "6" } },
        [
          _c(
            "b-row",
            { attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mb-5", attrs: { cols: "12", md: "9" } },
                [
                  _c(
                    "h1",
                    { staticClass: "mb-3 text-center heading text-primary" },
                    [_vm._v(" Medical Information ")]
                  ),
                  _c(
                    "p",
                    { staticClass: "sub-text text-center" },
                    [
                      _vm._v(
                        " If you would like to update your medical information please message your doctor "
                      ),
                      _c("b-link", { attrs: { to: "/messages/doctor" } }, [
                        _vm._v("here"),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "d-block d-md-none pb-3" }),
                ]
              ),
              _vm._l(_vm.healthQuestions, function (healthQuestion, index) {
                return _c(
                  "b-col",
                  {
                    key: index,
                    staticClass: "mb-3",
                    attrs: { cols: "12", md: "9" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "mb-3", attrs: { role: "group" } },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "sub-text mb-1 text-uppercase",
                            attrs: { for: `healthQuestion${index}` },
                          },
                          [
                            _vm._v(
                              _vm._s(index + 1) +
                                ". " +
                                _vm._s(healthQuestion.question)
                            ),
                          ]
                        ),
                        healthQuestion.question != "Stated allergies"
                          ? _c(
                              "p",
                              {
                                staticClass: "mb-2",
                                style: `color:${healthQuestion.highlightcolor}`,
                                attrs: { id: `healthQuestion${index}` },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(healthQuestion.answer) + " "
                                ),
                              ]
                            )
                          : _c(
                              "p",
                              {
                                staticClass: "mb-2",
                                style: `color:${healthQuestion.highlightcolor}`,
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      healthQuestion.answer.replace(
                                        /(\r\n|\n|\r)/gm,
                                        "\r\n"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                        _c("hr", { staticClass: "my-2" }),
                      ]
                    ),
                  ]
                )
              }),
            ],
            2
          ),
          _vm.editInformation
            ? [
                _c(
                  "h2",
                  {
                    staticClass: "mb-6 text-primary",
                    staticStyle: { "font-size": "26px" },
                  },
                  [_vm._v(" Personal Information ")]
                ),
                _c(
                  "b-alert",
                  { attrs: { show: _vm.alert.show, variant: _vm.alert.type } },
                  [_vm._v(_vm._s(_vm.alert.message))]
                ),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "firstName" },
                            },
                            [_vm._v("First Name:")]
                          ),
                          _c("b-form-input", {
                            staticClass: "fs-mask",
                            attrs: { id: "firstName", type: "text" },
                            model: {
                              value: _vm.user.firstname,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "firstname", $$v)
                              },
                              expression: "user.firstname",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "lastName" },
                            },
                            [_vm._v("Last Name:")]
                          ),
                          _c("b-form-input", {
                            staticClass: "fs-mask",
                            attrs: { id: "lastName", type: "text" },
                            model: {
                              value: _vm.user.lastname,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "lastname", $$v)
                              },
                              expression: "user.lastname",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "birthday" },
                            },
                            [_vm._v("Date of Birth:")]
                          ),
                          _c("flat-pickr", {
                            staticClass: "form-control pl-0 fs-mask",
                            attrs: {
                              autocomplete: "off",
                              id: "birthday",
                              placeholder: "MM/DD/YYYY",
                              config: _vm.config,
                            },
                            model: {
                              value: _vm.user.birthday,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "birthday", $$v)
                              },
                              expression: "user.birthday",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "gender" },
                            },
                            [_vm._v("Gender:")]
                          ),
                          _c("v-select", {
                            attrs: {
                              "append-to-body": "",
                              "calculate-position": _vm.withPopper,
                              label: "text",
                              options: _vm.genderOption,
                              reduce: (genderOption) => genderOption.value,
                              disabled: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "open-indicator",
                                  fn: function ({ attributes }) {
                                    return [
                                      _c(
                                        "b-icon",
                                        _vm._b(
                                          { attrs: { icon: "chevron-down" } },
                                          "b-icon",
                                          attributes,
                                          false
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              607575044
                            ),
                            model: {
                              value: _vm.gender,
                              callback: function ($$v) {
                                _vm.gender = $$v
                              },
                              expression: "gender",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "emailAddress" },
                            },
                            [_vm._v("Email Address:")]
                          ),
                          _c("b-form-input", {
                            staticClass: "fs-mask",
                            attrs: {
                              id: "emailAddress",
                              type: "text",
                              disabled: "",
                            },
                            model: {
                              value: _vm.user.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "email", $$v)
                              },
                              expression: "user.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-4", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "phone" },
                            },
                            [_vm._v("Phone Number:")]
                          ),
                          _c("b-form-input", {
                            staticClass: "fs-mask",
                            attrs: {
                              id: "phone",
                              formatter: _vm.formatPhoneNumber,
                            },
                            model: {
                              value: _vm.user.phoneno,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "phoneno", $$v)
                              },
                              expression: "user.phoneno",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "streetAddress" },
                            },
                            [_vm._v("Street Address:")]
                          ),
                          _c("b-form-input", {
                            attrs: { id: "streetAddress", type: "text" },
                            model: {
                              value: _vm.shippingDetails.address1,
                              callback: function ($$v) {
                                _vm.$set(_vm.shippingDetails, "address1", $$v)
                              },
                              expression: "shippingDetails.address1",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "apartment" },
                            },
                            [_vm._v("Apartment/Suite:")]
                          ),
                          _c("b-form-input", {
                            attrs: { id: "apartment", type: "text" },
                            model: {
                              value: _vm.shippingDetails.address2,
                              callback: function ($$v) {
                                _vm.$set(_vm.shippingDetails, "address2", $$v)
                              },
                              expression: "shippingDetails.address2",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "city" },
                            },
                            [_vm._v("City:")]
                          ),
                          _c("b-form-input", {
                            attrs: { id: "city", type: "text" },
                            model: {
                              value: _vm.shippingDetails.city,
                              callback: function ($$v) {
                                _vm.$set(_vm.shippingDetails, "city", $$v)
                              },
                              expression: "shippingDetails.city",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "state" },
                            },
                            [_vm._v("State:")]
                          ),
                          _c("v-select", {
                            attrs: {
                              id: "state",
                              type: "text",
                              options: _vm.stateOptions,
                            },
                            model: {
                              value: _vm.selectedState,
                              callback: function ($$v) {
                                _vm.selectedState = $$v
                              },
                              expression: "selectedState",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "country" },
                            },
                            [_vm._v("Country:")]
                          ),
                          _c("b-form-input", {
                            attrs: {
                              id: "country",
                              type: "text",
                              readonly: "",
                            },
                            model: {
                              value: _vm.shippingDetails.country,
                              callback: function ($$v) {
                                _vm.$set(_vm.shippingDetails, "country", $$v)
                              },
                              expression: "shippingDetails.country",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "zip" },
                            },
                            [_vm._v("Zip Code:")]
                          ),
                          _c("b-form-input", {
                            attrs: { id: "zip", type: "text" },
                            model: {
                              value: _vm.shippingDetails.zipcode,
                              callback: function ($$v) {
                                _vm.$set(_vm.shippingDetails, "zipcode", $$v)
                              },
                              expression: "shippingDetails.zipcode",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-3 mb-3" },
                  [
                    _c("b-col", { attrs: { cols: "12", md: "6" } }),
                    _c(
                      "b-col",
                      { attrs: { cols: "6", md: "3" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              pill: "",
                              block: "",
                              variant: "outline-info",
                            },
                            on: {
                              click: function ($event) {
                                _vm.editInformation = false
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "6", md: "3" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { pill: "", block: "", variant: "info" },
                            on: { click: _vm.saveUpdate },
                          },
                          [_vm._v("Update")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.intakeForm
            ? [
                _c(
                  "h2",
                  {
                    staticClass: "mb-6 text-primary",
                    staticStyle: { "font-size": "26px" },
                  },
                  [_vm._v(" Medical History ")]
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "mb-3", attrs: { cols: "12" } },
                      [
                        _c(
                          "b-row",
                          {
                            staticClass: "mb-4",
                            attrs: { "align-v": "center" },
                          },
                          [
                            _c(
                              "b-col",
                              { staticClass: "mb-3", attrs: { cols: "12" } },
                              [
                                _c(
                                  "h3",
                                  { staticClass: "bold d-letter-spacing" },
                                  [_vm._v("Health Questions")]
                                ),
                              ]
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-row",
                                  _vm._l(
                                    _vm.healthQuestions,
                                    function (healthQuestion, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: index,
                                          attrs: { cols: "12", md: "6" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "mb-3",
                                              attrs: { role: "group" },
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "d-letter-spacing",
                                                  attrs: {
                                                    for: `healthQuestion${index}`,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(index + 1) +
                                                      ". " +
                                                      _vm._s(
                                                        healthQuestion.question
                                                      )
                                                  ),
                                                ]
                                              ),
                                              healthQuestion.question !=
                                              "Stated allergies"
                                                ? _c("b-form-input", {
                                                    staticClass: "pl-0",
                                                    style: `color:${healthQuestion.highlightcolor}`,
                                                    attrs: {
                                                      id: `healthQuestion${index}`,
                                                      disabled: "",
                                                      type: "text",
                                                      value:
                                                        healthQuestion.answer,
                                                    },
                                                  })
                                                : _c("b-form-textarea", {
                                                    staticClass: "pl-0",
                                                    staticStyle: {
                                                      overflow: "hidden",
                                                    },
                                                    style: `color:${healthQuestion.highlightcolor}`,
                                                    attrs: {
                                                      disabled: "",
                                                      "max-rows": "15",
                                                      type: "text",
                                                      value:
                                                        healthQuestion.answer.replace(
                                                          /(\r\n|\n|\r)/gm,
                                                          "\r\n"
                                                        ),
                                                    },
                                                  }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mb-5" },
                  [
                    _c("b-col", { attrs: { cols: "12", md: "6" } }),
                    _c("b-col", { attrs: { cols: "6", md: "3" } }),
                    _c(
                      "b-col",
                      { attrs: { cols: "6", md: "3" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { pill: "", block: "", variant: "info" },
                            on: { click: _vm.saveUpdate },
                          },
                          [_vm._v("Back")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }